<template>
  <b-card>
    <!-- Title -->
    <b-card-title class="text-primary">
      Register Store
    </b-card-title>

    <!-- FORM -->
    <b-form>
      <!-- Unique Store Name -->
      <b-form-group
        label="Store name"
        label-for="storeName"
        :invalid-feedback="getErrorMessage(errors, 'storeName')"
        :state="getErrorState(errors, 'storeName')"
      >
        <b-form-input
          :state="getErrorState(errors, 'storeName')"
          type="text"
          id="storeName"
          v-model="form.storeName"
          placeholder="Store Name"
          @input="filterStoreUrlName"
          trim
        />
      </b-form-group>

      <!-- Store URL Name -->
      <b-form-group
        label="Unique store url"
        label-for="storeUrlName"
        :invalid-feedback="getErrorMessage(errors, 'storeUrlName')"
        :state="getErrorState(errors, 'storeUrlName')"
      >
        <b-form-input
          :state="getErrorState(errors, 'storeUrlName')"
          type="text"
          id="storeUrlName"
          v-model="form.storeUrlName"
          placeholder="Store URL"
          @input="filterStoreUrlName"
          trim
        />
      </b-form-group>
      <p>
        {{ `/${form.storeUrlName}` | customerUrl }}
      </p>

      <b-button @click="onRegister" variant="primary"> Register </b-button>
    </b-form>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { registerStore } from "../../../apis/user";
export default {
  created() {
    // checking if store already exists, redirecting to home
    if (this.store) {
      this.redirectToDashboard();
      return;
    }
  },
  data() {
    return {
      form: {
        storeName: "",
        storeUrlName: "",
        mrpHidden: false,
        autoWhatsappOrder: true,
      },
      errors: null,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      store: "merchant/store/getStore",
    }),
  },
  methods: {
    ...mapActions({
      setStore: "merchant/store/setStore",
    }),
    onRegister() {
      // checking if store already exists, redirecting to home
      if (this.store) {
        this.redirectToDashboard();
        return;
      }

      this.errors = null;
      const formData = this.toFormData(this.form);
      registerStore(formData, this.token)
        .then((data) => {
          // setting store in state
          this.setStore(data);
          this.redirectToDashboard();
          return;
        })
        .catch(({ data }) => {
          this.errors = data.errors;
        });
    },
    redirectToDashboard() {
      this.$router.replace({ name: "MerchantDashboard" });
    },

    filterStoreUrlName(value) {
      var storeUrlName = value;
      // add code, to trim all white spaces,
      storeUrlName = storeUrlName.replace(/\s/g, "");
      // remove special characters
      storeUrlName = storeUrlName.replace(/[^a-zA-Z0-9]/g, "");
      // convert all to lower case
      storeUrlName = storeUrlName.toLowerCase();
      // trimming again for safety
      storeUrlName = storeUrlName.trim();
      this.form.storeUrlName = storeUrlName;
    },
  },
};
</script>
