import httpClient from "../functions/httpClient";
import axios from "axios";
import { getHeaders } from "./index";

// -------------------------------------- PROFILE --------------------------------------
export const getProfile = (token) => {
	return httpClient.GET("/user", getHeaders(token));
};
export const changePassword = (form, token) => {
	return httpClient.PUT("/user/changePassword", getHeaders(token), null, form);
};
// -------------------------------------- END PROFILE --------------------------------------

// -------------------------------------- ADDRESSES --------------------------------------
export const getAddresses = (token) => {
	return httpClient.GET("/user/addresses", getHeaders(token));
};
export const getAddress = (addressId, token) => {
	return httpClient.GET("/user/addresses/" + addressId, getHeaders(token));
};
export const addAddress = (addressForm, token) => {
	return httpClient.POST("/user/addresses", getHeaders(token), null, addressForm);
};
export const updateAddress = (addressId, addressForm, token) => {
	return httpClient.PUT("/user/addresses/" + addressId, getHeaders(token), null, addressForm);
};
export const deleteAddress = (addressId, token) => {
	return httpClient.DELETE("/user/addresses/" + addressId, getHeaders(token));
};
// -------------------------------------- END ADDRESSES --------------------------------------

// -------------------------------------- STORE --------------------------------------
export const userStoreExists = (token) => {
	return httpClient.GET("/user/store/exists", getHeaders(token));
};
export const checkStoreUrlName = (storeUrlName, token) => {
	return httpClient.GET("/user/store/url", getHeaders(token), { storeUrlName });
};
export const registerStore = (storeForm, token) => {
	return httpClient.POST("/user/store", getHeaders(token), null, storeForm);
};

// --------------------------------------END STORE --------------------------------------

// -------------------------------------- ORDER --------------------------------------
export const placeOrder = (storeUrlName, addressId) => {
	const body = {};
	if (addressId) {
		body["addressId"] = addressId;
	}
	const url = `/user/stores/${storeUrlName}/cart/order`;
	return axios.post(url, body);
};
// -------------------------------------- END ORDER --------------------------------------
// -------------------------------------- CART ITEMS --------------------------------------
export const getCartItems = (storeUrlName) => {
	const url = `/user/stores/${storeUrlName}/cart/items`;
	return axios.get(url);
};
export const addItemToCart = (storeUrlName, productId, quantity) => {
	const url = `/user/stores/${storeUrlName}/cart/items`;
	const body = { productId, quantity };
	return axios.post(url, body);
};
export const updateItemQuantity = (storeUrlName, cartItemId, quantity) => {
	const url = `/user/stores/${storeUrlName}/cart/items/${cartItemId}`;
	const body = { quantity };
	return axios.put(url, body);
};
export const deleteItemFromCart = (storeUrlName, cartItemId) => {
	const url = `/user/stores/${storeUrlName}/cart/items/${cartItemId}`;
	return axios.delete(url);
};
// -------------------------------------- END CART ITEMS --------------------------------------
// -------------------------------------- END CART --------------------------------------
